<template>
  <v-container
    class="mb-10"
    style="height: 100%"
  >
    <v-row>
      <Title title="Carrinho de orçamento" />
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="desserts"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-avatar
              size="100"
              rounded
            >
              <v-img :src="$store.state.imageProductPath + item.image" />
            </v-avatar>
          </template>

          <template v-slot:[`item.row`]="{ item }">
            <v-text-field
              class="my-1"
              placeholder="1ª Qtd."
              v-mask="['########']"
              dense
              rounded
              filled
              hide-details
              v-model="item.row[0].quantity"
            />
            <v-text-field
              class="my-1"
              placeholder="2ª Qtd."
              v-mask="['########']"
              dense
              rounded
              filled
              hide-details
              v-model="item.row[1].quantity"
            />
            <v-text-field
              class="my-1"
              placeholder="3ª Qtd."
              v-mask="['########']"
              dense
              rounded
              filled
              hide-details
              v-model="item.row[2].quantity"
            />
          </template>
          <template v-slot:[`item.actions`]="{}">
            <v-btn
              @click="removeProduct(item)"
              icon
            >
              <v-icon
                color="error"
                small
                >fas fa-trash</v-icon
              >
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="end">
        <v-btn
          @click="goToHome"
          class="mr-2"
          rounded
          outlined
          color="primary"
        >
          Continuar comprando
        </v-btn>
        <v-btn
          @click="solicited"
          rounded
          color="primary"
        >
          Enviar Orçamento
        </v-btn>
      </v-col>
    </v-row>
    <!-- Mensagem de envio -->
  </v-container>
</template>

<script>
export default {
  components: {
    Title: () => import('@/components/web/layout/widgets/Title'),
  },
  data() {
    return {
      alert: false,
      success_text: 'Orçamento Enviado com Sucesso!',
      headers: [
        {
          text: 'Imagem',
          align: 'start',
          sortable: false,
          value: 'image',
          width: '100',
        },
        { text: 'Código', value: 'reference', sortable: false, width: 100 },
        { text: 'Nome', value: 'name', sortable: false },
        { text: 'Cor', value: 'color.name', sortable: false },
        { text: 'Gravação', value: 'print_name', sortable: false },
        { text: 'Quantidades', value: 'row', sortable: false, width: 150 },
        { text: 'Ações', value: 'actions', width: 50, sortable: false },
      ],
      desserts: [],
      budget: null,
    }
  },
  methods: {
    getColor(calories) {
      if (calories > 400) return 'red'
      else if (calories > 200) return 'orange'
      else return 'green'
    },
    removeProduct(item) {
      let idx = this.desserts.indexOf(item)
      this.desserts.splice(idx, 1)
      localStorage.setItem('Cart', JSON.stringify(this.desserts))
    },
    solicited: function () {
      let client_id = this.$cookies.get('id')
      let contact_id = this.$cookies.get('contact_id')

      //Verificação de autenticação
      if (client_id) {
        let post = {
          client_id: parseInt(client_id),
          client_contact_id: parseInt(contact_id),
          seller_id: null,
          products: this.desserts,
        }

        this.$api
          .post(`/products/site/budget`, post)
          .then(() => {
            //limpa os dados locais
            this.desserts = []
            localStorage.setItem('Cart', JSON.stringify(this.desserts))

            this.modalShow()
          })
          .catch(() => {
            this.errorModal()
          })
          .finally(() => {
            this.selectedImg = this.product.product_images[0].src
            this.product.product_images.map((img) => {
              this.colors.push(img.color)
            })
          })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Já fez seu Login?',
          caption:
            'Por favor, realize o login ou  efetue um cadastro para se tornar um de nossos clientes.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
    modalShow() {
      this.$store.commit('setModal', {
        show: true,
        component: 'success',
        text: 'Orçamento Envidado Com Sucesso',
        caption:
          'Seu orçamento foi envidado para um de nossos vendedores. Iremos analizar seu pedido e entraremos em contato.Muito obrigado pela preferência!',
        confirm: () => {
          this.goTo()
        },
      })
    },
    errorModal() {
      this.$store.commit('setModal', {
        show: true,
        component: 'error',
        text: 'Ocorreu um erro no envio',
        caption:
          'Por favor, entre em contato com um de nossos representantes. Ou tente novamente mais tarde.',
        confirm: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    goTo() {
      this.$router.push({ name: 'home' })
    },
    goToHome() {
      this.$router.push('/')
    },
  },
  mounted() {
    const Cart = JSON.parse(localStorage.getItem('Cart'))
    this.desserts = Cart
    console.log("this.desserts: ",this.desserts);
  },
  beforeDestroy() {
    //Atualiza Carrinho
    localStorage.setItem('Cart', JSON.stringify(this.desserts))
  },
}
</script>

<style>
.dialog-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
</style>
